<template>
  <div>
    <v-card>
      <v-card-title>
        <VSelect
          v-model="stage"
          :items="stages"
          :loading="isLoading"
          item-value="id"
          item-text="name"
          outlined
          hide-details
          :label="$('stage')"
        ></VSelect>
        <v-spacer></v-spacer>
        <v-btn
          id="btn1"
          v-can="'timetable.create'"
          color="primary"
          dark
          @click="dialogModal(true, 'create')"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
          {{ $('create') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        v-if="!isLoading && stage"
        class="dd"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :disable-filtering="true"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          itemsPerPageOptions:[10, 20, 50, -1],
          itemsPerPageText: $t('dataTable.itemsPerPageText'),
          pageText: $t('dataTable.pageText'),
          itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
        }"
        :hide-default-header="!$vuetify.breakpoint.mobile"
        @update:page="fetch"
        @update:items-per-page="fetch"
      >
        <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
          <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
        </template>
        <template v-slot:header.actions="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div id="actions" class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  color="dark"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <!--<menu-item :label="$('students')" color="primary" icon="mdi-account-school" @click="$router.push({name: 'student', params: { stageId: item.id }})"></menu-item>-->
                <menu-item v-can="'timetable.edit'" :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
                <menu-item v-can="'timetable.delete'" :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template v-slot:item.day="{ item }">
          {{ $(`days.${item.day}`) }}
        </template>
        <template v-slot:item.study_type="{ item }">
          {{ $t(`study_types.${item.study_type}`) }}
        </template>
        <template v-slot:item.from="{ item }">
          {{ timeFormat(item.from) }}
        </template>
        <template v-slot:item.to="{ item }">
          {{ timeFormat(item.to) }}
        </template>
      </v-data-table>
      <action-dialog
        :dialog-modal="dialogModal"
        :data="dialogData"
        :translation-key="translationKey"
        @update:table="fetch"
      ></action-dialog>
    </v-card>
  </div>
</template>

<script>
import axios from '@axios'
import ActionDialog from './dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper'
import { getFilters, getOptions } from '@/components/dataTables/helper'
import MenuItem from '@/components/menu/menuItem.vue'

export default {
  name: 'Timetables',
  components: {
    DatatableHeader,
    ActionDialog,
    MenuItem,
  },
  data: () => ({
    translationKey: 'pages.timetable',
    filters: false,
    items: [],
    stages: [],
    subjects: [],
    stage: null,
    isLoading: true,
    total: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
      subjects: [],
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: 'day',
          align: 'center',
          sortable: false,
          value: 'day',
          sort: true,
        },
        {
          text: 'study_type',
          align: 'center',
          sortable: false,
          value: 'study_type',
          sort: true,
        },
        {
          text: 'subject',
          align: 'center',
          sortable: false,
          value: 'subject.name',
          sort: true,
          filter: {
            menu: false,
            type: 'text',
            methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
          },
        },
        {
          text: 'from',
          align: 'center',
          sortable: false,
          value: 'from',
          sort: true,
        },
        {
          text: 'to',
          align: 'center',
          sortable: false,
          value: 'to',
          sort: true,
        },
        {
          text: 'class_room',
          align: 'center',
          sortable: false,
          value: 'class_room',
          sort: true,
        },
        {
          text: 'actions',
          value: 'actions',
          align: 'center',
        },
      ]
    },
  },
  watch: {
    stage: 'fetch',
  },
  async mounted() {
    this.options = getOptions(this.$route, this.options)
    const response = await axios.get('/stages')
    this.stages = response.data.data
    this.isLoading = false
  },
  methods: {
    async fetch() {
      this.loading = true
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options)
        try {
          await this.$router.replace({ query: this.options })
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
      window.scrollTo(0, 0)
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage)
      const filters = getFilters(this.headers)
      const query = ObjectToQuery({
        ...this.options,
        filters,
        stage_id: this.stage,
      })
      const response = await axios.get(`/timetables/dt?${query}`)
      this.items = response.data.data.data
      this.total = response.data.data.total
      const responseSubjects = await axios.get('/subjects', {
        params: {
          stage_id: this.stage,
        },
      })
      this.subjects = responseSubjects.data.data
      this.loading = false
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog
      this.dialogData.type = type
      this.dialogData.subjects = this.subjects
      this.dialogData.id = id
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters)
    },
    timeFormat(time) {
      const am = this.$('timePicker.am')
      const pm = this.$('timePicker.pm')
      if (!time) {
        return ''
      }

      const [hour, minutes] = time.split(':')
      const hourNumber = Number(hour)
      if (hourNumber > 12) {
        return `${String(hourNumber - 12)
          .padStart(2, '0')}:${minutes} ${pm}`
      }

      return `${hour}:${minutes} ${hourNumber < 12 ? am : pm}`;
    },
  },

}
</script>
